import React, { useState } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';

import styled from 'styled-components';

import { motion, AnimatePresence } from 'framer-motion';

// Hooks Querys & Context
import { useScrollFreeze, useWindowSize } from '../../hooks';
import { useAppContext } from '../../state';
import { useNavItems } from '../../GraphQl/useNavItems';

// Components
import { StandardContainer } from '../common';
import { PageLink } from '../sanity';

const StyledDrawNav = styled(motion.div)`
  --textColor: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--blue);
  color: var(--white);
  z-index: 994;
  display: flex;
  align-items: center;
  justify-content: center;
  > nav {
    margin-top: 80px;
    display: grid;
    width: 100%;
    font-family: var(--headingFont);
    > div.feature {
      font-size: var(--h3);
      grid-column: 1;
      display: grid;
    }
    > div.sub {
      font-size: var(--h5);
      display: grid;
      grid-auto-rows: min-content;
    }
  }
  @media screen and (min-width: 480px) {
    > nav {
      grid-template-columns: 2fr 1fr;
      grid-row-gap: 0.5rem;
      grid-column-gap: 1rem;
      margin-top: 120px;
    }
  }
`;

const StyledSectionBackground = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--black);
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    object-fit: cover;
  }
`;

const StyledNavLink = styled(PageLink)`
  opacity: ${(props) => (props.unFocus ? 0.5 : 1)};
  transition: opacity 0.5s ease;
  padding: 0.35rem 0;
  display: flex;
  /* Medium */
  @media screen and (min-width: 480px) {
    padding: 0.75rem 0;
  }
`;
const StyledNavGatsbyLink = styled(Link)`
  opacity: ${(props) => (props.unFocus ? 0.5 : 1)};
  transition: opacity 0.5s ease;
  padding: 0.35rem 0;
  display: flex;
  color: #fff;
  /* Medium */
  @media screen and (min-width: 480px) {
    padding: 0.75rem 0;
  }
`;

// Animation Variants
const wrapperVars = {
  open: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
  closed: {
    opacity: 0,
    transition: { staggerChildren: 0.1, staggerDirection: -1, delay: 0.5 },
  },
};

const linkVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      damping: 30,
    },
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      type: 'spring',
      damping: 30,
    },
  },
};

const SectionBackground = ({ hoverImage, _key }) => {
  return (
    <AnimatePresence>
      {hoverImage && (
        <StyledSectionBackground
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={_key}
        >
          <Image {...hoverImage} />
        </StyledSectionBackground>
      )}
    </AnimatePresence>
  );
};

const NavInner = () => {
  useScrollFreeze();
  const { closeNav } = useAppContext();
  const { primary, secondary } = useNavItems();
  const [activeSection, setActiveSection] = useState(null);
  const { width } = useWindowSize();
  return (
    <StyledDrawNav
      variants={wrapperVars}
      initial='closed'
      animate='open'
      exit='closed'
    >
      {/* Background */}
      <SectionBackground {...activeSection} />
      <StandardContainer as='nav' padH wrappedNarrow>
        {/* Main Links */}
        <div className='feature'>
          {primary.map((item, i) => {
            const { pageLink } = item;
            return (
              <motion.div className='feature' variants={linkVariants}>
                <StyledNavLink
                  {...pageLink}
                  onMouseOver={() => width >= 1024 && setActiveSection(item)}
                  onMouseOut={() => width >= 1024 && setActiveSection(null)}
                  unFocus={activeSection && activeSection._key !== item._key}
                  onClick={closeNav}
                  state={i === 0 && { year: '2023' }}
                />
              </motion.div>
            );
          })}
        </div>
        {/* Secondary Links */}
        <div className='sub'>
          {secondary.map((item) => {
            const { _type } = item;
            return (
              <motion.div variants={linkVariants}>
                {_type === 'pageLink' ? (
                  <StyledNavLink
                    {...item}
                    onMouseOver={() => width >= 1024 && setActiveSection(item)}
                    onMouseOut={() => width >= 1024 && setActiveSection(null)}
                    unFocus={activeSection && activeSection._key !== item._key}
                    onClick={closeNav}
                  />
                ) : (
                  _type === 'navPath' && (
                    <StyledNavGatsbyLink
                      to={item.path}
                      onMouseOver={() =>
                        width >= 1024 && setActiveSection(item)
                      }
                      onMouseOut={() => width >= 1024 && setActiveSection(null)}
                      unFocus={
                        activeSection && activeSection._key !== item._key
                      }
                      onClick={closeNav}
                    >
                      {item.linkText}
                    </StyledNavGatsbyLink>
                  )
                )}
              </motion.div>
            );
          })}
        </div>
      </StandardContainer>
    </StyledDrawNav>
  );
};

export const DrawNav = () => {
  const { navOpen } = useAppContext();
  return <AnimatePresence>{navOpen && <NavInner />}</AnimatePresence>;
};
