import React from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage } from 'formik';

import ChevronIcon from '../../svg/chevron.svg';

export const StyledInput = styled.input`
  background: transparent;
  grid-row: 1;
  grid-column: 1 / span 2;
  border: none;
  border-bottom: 1px solid var(--textColor);
  font-family: var(--mediumFont);
  font-size: var(--h7);
  padding: 0.8rem 0;
  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--textColor);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--textColor);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: var(--textColor);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: var(--textColor);
  }
  :-webkit-autofill,
  :-webkit-autofill:focus,
  :-webkit-autofill:hover {
    background-color: transparent !important;
  }
`;
export const StyledFormikField = styled(Field)`
  background: transparent;
  grid-row: 1;
  grid-column: 1 / span 2;
  border: none;
  border-bottom: 1px solid var(--textColor);
  font-family: var(--mediumFont);
  font-size: var(--h7);
  padding: 0.8rem 0;
  width: 100%;
  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--textColor);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--textColor);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: var(--textColor);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: var(--textColor);
  }
  :-webkit-autofill,
  :-webkit-autofill:focus,
  :-webkit-autofill:hover {
    background-color: transparent !important;
  }
`;

export const StyledFormikError = styled(ErrorMessage)`
  margin-top: 1rem;
  display: block;
`;

export const StyledSelect = styled.select`
  border: none;
  background: transparent;
  width: 100%;
  padding: 0.5rem 0;
  font-size: var(--h6);
  font-family: var(--mediumFont);
  color: var(--blue);
`;

export const StyledSelector = styled((props) => (
  <div {...props}>
    <ChevronIcon />
    {props.children}
  </div>
))`
  display: grid;
  grid-template-columns: 1fr 1rem;
  * {
    grid-row: 1;
  }
  > select {
    grid-column: 1 / span 2;
    padding: 0.5rem 0;

    border: none;
    background: transparent;
    appearance: none;
    font-size: var(--h6);
    font-family: var(--mediumFont);
    color: var(--textColor);
  }
  > svg {
    grid-column: 2;
    width: 1rem;
    fill: var(--textColor);
    align-self: center;
  }
`;

export const StyledCheckbox = styled((props) => (
  <input type="checkbox" {...props} />
))`
  background: transparent;
  border: 2px solid var(--textColor);
  width: 20px;
  height: 20px;
  border-radius: 100px;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
  &:checked {
    background: var(--textColor);
  }
`;

export const StyledCurrencyInput = styled.div`
  display: grid;
  max-width: 350px;
  margin: 0 auto;
  > input {
    grid-column: 1 / span 2;
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--textColor);
    font-family: var(--mediumFont);
    font-size: var(--h7);
    padding: 0.8rem 0;
    &:focus {
      outline: none;
    }
  }
`;
