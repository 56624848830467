import { useStaticQuery, graphql } from 'gatsby';

import { PageLinkFields } from '../GraphQl/queryFragments'; // eslint-disable-line

export const useNavItems = () => {
  const { sanityMainNav } = useStaticQuery(graphql`
    query {
      sanityMainNav {
        primary {
          _key
          hoverImage {
            ...ImageWithPreview
          }
          pageLink {
            ...PageLinkFields
          }
        }
        secondary {
          ... on SanityNavPath {
            _key
            _type
            path
            linkText
          }
          ... on SanityPageLink {
            _type
            _key
            linkText
            page {
              ... on SanityEvent {
                id
                slug {
                  current
                }
                _type
              }
              ... on SanityEventCalendar {
                id
                _type
                slug {
                  current
                }
              }
              ... on SanityHomePage {
                id
                _type
              }
              ... on SanityStandardPage {
                id
                _type
                slug {
                  current
                }
              }
              ... on SanityMembershipForm {
                id
                _type
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  `);
  return sanityMainNav || {};
};
