import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';

// Hooks
import { useNavItems } from '../../GraphQl/useNavItems';
import { useWindowSize } from '../../hooks';
import { useAppContext } from '../../state';

//Components
import { PageLink } from '../sanity';

// Styles
const StyledPrimaryNav = styled(Row)`
  > a {
    margin: 0 1rem;
    font-size: var(--h6);
  }
`;

const variants = {
  open: {
    opacity: 1,
    transition: {
      delay: 0.8,
      damping: 300,
    },
  },
  closed: {
    opacity: 0,
  },
};

export const PrimaryNav = () => {
  const { primary } = useNavItems();
  const { width } = useWindowSize();
  const { navOpen } = useAppContext();

  return (
    width > 1280 && (
      <AnimatePresence>
        {!navOpen && (
          <motion.div
            variants={variants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <StyledPrimaryNav as="nav" hide={navOpen}>
              {primary.map(({ pageLink }, i) => (
                <PageLink state={i === 0 && { year: '2023' }} {...pageLink} />
              ))}
            </StyledPrimaryNav>
          </motion.div>
        )}
      </AnimatePresence>
    )
  );
};
