import React from 'react';
import styled from 'styled-components';

import { SanityImage } from '../sanity';

const StyledSponsorLogos = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  padding: 3rem 0;
  a {
    max-width: 150px;
  }
  h4 {
    font-family: 'NeueMedium';
  }
  @media screen and (min-width: 480px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  }
`;

const SponsorItem = ({ title, logo, website }) => {
  return (
    <a href={website} target="_blank">
      <h4>{title}</h4>
      {logo && logo.asset && <SanityImage image={logo} />}
    </a>
  );
};

export const SponsorLogos = ({ items }) => {
  return (
    <StyledSponsorLogos>
      {items?.map((item) => (
        <SponsorItem {...item} />
      ))}
    </StyledSponsorLogos>
  );
};
