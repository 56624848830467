import React from 'react';
import styled from 'styled-components';

import SanityImage from 'gatsby-plugin-sanity-image';
import BackgroundImage from 'gatsby-background-image';
import ReactPlayer from 'react-player';

// Background Video
export const StyledBackgroundVideo = styled((props) => (
  <ReactPlayer
    playing
    playsinline
    width="100%"
    height="100%"
    loop={true}
    muted
    background={true}
    {...props}
  />
))`
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  video {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
`;

export const StyledCoverBackground = styled(BackgroundImage)`
  --hX: ${(props) =>
    (props.hotspot && `${props.hotspot.x * 100}%`) || 'center'};
  --hY: ${(props) =>
    (props.hotspot && `${props.hotspot.y * 100}%`) || 'center'};

  height: 100%;
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;

  background-position: var(--hX) var(--hY) !important;
`;

const StyledAspectImage = styled.div`
  --aR: ${(props) => props.padBot || 100}%;

  width: 100%;
  height: 0px;
  padding-bottom: var(--aR);
  position: relative;

  > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`;

export const AspectImage = ({ padBot, image, ...props }) => {
  return (
    <StyledAspectImage padBot={padBot} className="aspect-img">
      {image && image.asset && <SanityImage {...image} />}
    </StyledAspectImage>
  );
};

export const PlaceHolderImage = styled.div`
  --aR: ${(props) => props.padBot || 100}%;

  width: 100%;
  height: 0px;
  padding-bottom: var(--aR);
  position: relative;
`;
