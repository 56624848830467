import React, { useEffect } from 'react';

import { useAppContext } from '../../state';
import { useInView } from 'react-intersection-observer';

import { StandardContainer } from '../common';

// Need to revisit this

export const BlockWrapper = ({ children, blockBg, ...props }) => {
  const { setActiveBlockBg } = useAppContext();
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
  });
  const { hex } = blockBg || {};
  const background = hex || null;
  useEffect(() => {
    setActiveBlockBg((prevState) => (inView ? background : prevState));
  }, [inView, background, setActiveBlockBg]);
  return (
    <StandardContainer
      ref={ref}
      className={inView && 'active'}
      {...props}
      as="section"
    >
      {children}
    </StandardContainer>
  );
};
