import React from 'react';
import styled from 'styled-components';

import { StandardContainer, StyledHeading } from '../common';

// Blog Quote
const StyledBlogQuote = styled(StandardContainer)`
  text-align: center;
  display: grid;
  grid-template-rows: var(--bcLine) auto var(--bcLine);
  grid-row-gap: 2rem;
  * {
    justify-self: center;
  }
  &:before,
  &:after {
    content: '';
    background: var(--textColor);
    width: 2px;
    justify-self: center;
  }
`;

export const BlogQuote = ({ heading, author }) => {
  return (
    <StyledBlogQuote marginTop={100} marginBottom={100}>
      <div>
        <StyledHeading size="h4" measureWide fontReg>
          {heading}
        </StyledHeading>
        {author && (
          <StyledHeading size="h6" as="h6">
            {author}
          </StyledHeading>
        )}
      </div>
    </StyledBlogQuote>
  );
};
