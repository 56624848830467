import React from 'react';

import { TextLink } from '../common';

export const FileLink = ({ linkText, file }) => {
  const path = (file && file.asset.url) || null;
  return (
    <TextLink href={path} target="_blank" as="a">
      {linkText}
    </TextLink>
  );
};
