import styled from 'styled-components';
import is, { match, isNot } from 'styled-is';

export const StyledHeading = styled.h2`
  --vMargin: ${(props) => props.vMargin || 0.5}em;

  font-family: var(--headingFont);
  color: var(--headingColor);
  line-height: var(--lhHeading);

  /* Font Modifiers */
  ${is('fontReg')`
    --headingFont: var(--bodyFont);
  `}
  ${is('fontBold')`
    --headingFont: var(--boldFont);
  `}
  /* Style Modifiers */
  ${is('ttu')`
    text-transform: uppercase;
  `}
  /* Margin Modifiers */
  ${isNot('noMargin')`
    margin-bottom: var(--vMargin);
  `}
  /* Aligment */
  ${is('center')`
    text-align: center;
    margin:0 auto;
  `}
  /* Size Modifiers */
  ${match('size', 'h1')`
    font-size: var(--h1);
  `}
  ${match('size', 'h2')`
    font-size: var(--h2);
  `}
  ${match('size', 'h3')`
    font-size: var(--h3);
  `}
  ${match('size', 'h4')`
    font-size: var(--h4);
  `}
  ${match('size', 'h5')`
    font-size: var(--h5);
  `}
  ${match('size', 'h6')`
    font-size: var(--h6);
  `}
  ${match('size', 'h7')`
    font-size: var(--bodyText);
  `}
  ${match('size', 'h8')`
    font-size: var(--bodySmall);
  `}
  /* Width Modifiers */
  ${is('measureNarrow')`
    max-width: 10em;
  `}
  ${is('measure')`
    max-width: var(--mHeading);
  `}
  ${is('measureWide')`
    max-width: var(--mStandard);
  `}
   /* Alignment Modifiers */
   ${is('centerText')`
    text-align: center;
  `}
   ${is('measure', 'centerText')`
      margin-left: auto;
      margin-right: auto;
  `}
   ${is('measureWide', 'centerText')`
      margin-left: auto;
      margin-right: auto;
  `}
`;
