import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// SVG
import CloseIcon from '../../svg/close.svg';

import { StyledHeading, AspectImage } from '../common';
import { StandardModal } from '../modals/standardModal';
import { SanityPortableText } from '../sanity';
import { useBios } from '../../GraphQl/useBios';

const StyledArtistBio = styled(motion.div)`
  background: #f2f7fb;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-row-gap: 1rem;
  align-self: flex-start;
  position: relative;
  > button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: +1;
    svg {
      width: 40px;
      stroke: var(--blue);
    }
  }
  .aspect-img {
    --aR: 100%;
  }
  .gatsby-image-wrapper {
    height: 0;
    padding-bottom: var(--aR);
  }
  > div:nth-child(3) {
    padding: 2rem var(--pH);
  }

  /* Large */
  @media screen and (min-width: 480px) {
    grid-template-columns: 1fr 1fr;
    align-self: center;
    .aspect-img {
      --aR: 140%;
    }
    > div:nth-child(3) {
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      right: 0;
      overflow-y: auto;
      padding: 3rem 3rem;
    }
  }
`;

const ArtistBio = ({ onClose, headshot, name, position, _rawBiography }) => {
  const { asset, hotspot } = headshot || {};
  return (
    <StyledArtistBio
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 200 }}
      transition={{ type: 'spring', damping: 20 }}
    >
      <button onClick={onClose}>
        <CloseIcon />
      </button>
      <div>
        {headshot && headshot.asset && <AspectImage image={headshot} />}
      </div>
      <div>
        <StyledHeading size="h3" as="h2" noMargin>
          {name}
        </StyledHeading>
        <StyledHeading size="h6" as="h6">
          {position}
        </StyledHeading>
        <SanityPortableText text={_rawBiography} />
      </div>
    </StyledArtistBio>
  );
};
const StyledBioEnticer = styled.span`
  cursor: pointer;
`;

export const BioReference = ({ children, mark }) => {
  const [isOpen, setOpen] = useState(false);
  const bios = useBios();

  //find the bio._id that matches the mark.artist._ref
  const bio = bios?.find((b) => b?._id === mark?.artist?._ref);

  return (
    bio && (
      <>
        <StyledBioEnticer onClick={() => setOpen(true)}>
          {children}
        </StyledBioEnticer>
        <StandardModal isOpen={isOpen}>
          <ArtistBio {...bio} onClose={() => setOpen(false)} />
        </StandardModal>
      </>
    )
  );
};
