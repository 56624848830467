import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { isNot } from 'styled-is';
import { motion, AnimatePresence } from 'framer-motion';

// Hooks
import { useScrollFreeze } from '../../hooks';

const StyledModalWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: ${(props) => (props.bgWhite ? '#ffffff' : 'rgba(0, 0, 0, 0.8)')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow-y: ${(props) => (props.overflowYNone ? 'hidden' : 'auto')};
  display: flex;
  justify-content: center;

  @media screen and (min-width: 480px) {
    ${isNot('fullWidth')`
    padding-left: ${(props) => (props.noPad ? '0' : 'var(--pH)')};
    padding-right: ${(props) => (props.noPad ? '0' : 'var(--pH)')};
  `}
  }
`;
const ModalInner = ({ children, ...props }) => {
  // useScrollFreeze();
  return <StyledModalWrapper {...props}>{children}</StyledModalWrapper>;
};

export const StandardModal = ({
  children,
  noPad,
  isOpen,
  bgWhite = false,
  fullWidth = false,
  overflowYNone,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <ModalInner
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          fullWidth={fullWidth}
          bgWhite={bgWhite}
          noPad={noPad}
          overflowYNone={overflowYNone}
        >
          {children}
        </ModalInner>
      )}
    </AnimatePresence>
  );
};

const PersistantModalInner = ({ children, ...props }) => {
  return <StyledModalWrapper {...props}>{children}</StyledModalWrapper>;
};

const persitantVars = {
  open: {
    pointerEvents: 'all',
    opacity: 1,
  },
  closed: {
    pointerEvents: 'none',
    opacity: 0,
  },
};

export const PersistantModal = ({
  children,
  isOpen = false,
  bgWhite = false,
  fullWidth = false,
}) => {
  return (
    <PersistantModalInner
      variants={persitantVars}
      initial="closed"
      animate={isOpen ? 'open' : 'closed'}
      transition={{ duration: 0.3 }}
      fullWidth={fullWidth}
      bgWhite={bgWhite}
    >
      {children}
    </PersistantModalInner>
  );
};
