import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Hooks
import { TextButton } from '../common';
import { StandardModal } from '../modals/standardModal';

// SVG
import CloseIcon from '../../svg/close.svg';

const StyledSpotifyIframe = styled(motion.div)`
  align-self: center;
  position: relative;
  padding-top: 1rem;
  button {
    position: absolute;
    top: 0;
    right: 0;
  }
  svg {
    width: 30px;
    fill: var(--white);
  }
`;

export const SpotifyIframe = ({ spotifyID, type, onClose }) => {
  return (
    spotifyID &&
    type && (
      <StyledSpotifyIframe
        padH
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        transition={{ delay: 0.2 }}
        key="embed"
      >
        <button onClick={onClose}>
          <CloseIcon />
        </button>
        <iframe
          src={`https://open.spotify.com/embed/${type}/${spotifyID}`}
          width="300"
          height="380"
          frameborder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </StyledSpotifyIframe>
    )
  );
};

export const SpotifyWidget = ({ linkText, ...props }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <TextButton onClick={() => setOpen(true)}>{linkText}</TextButton>
      <StandardModal isOpen={isOpen}>
        <SpotifyIframe {...props} onClose={() => setOpen(false)} />
      </StandardModal>
    </>
  );
};
