import React from 'react';
import styled from 'styled-components';

import useFormValidation from '../../hooks/useFormValidation';
import { convertToKebabCase } from '../../utils/helpers';

import { StyledInput, StyledHeading, StandardButton } from '../common';

const StyledNetlifyFormEmbed = styled.form`
  > section {
    text-align: center;
    margin: 4rem 0;
  }
  > article {
    display: grid;
    grid-row-gap: 1rem;
    input,
    textArea {
      display: flex;
      width: 100%;
    }
  }
  button {
    font-size: var(--h7);
    display: block;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const NetlifyFormEmbed = ({ formFields, title }) => {
  const formName = convertToKebabCase(title);

  const { handleSubmit, handleChange, success, formError } = useFormValidation(
    {},
  );
  return (
    <StyledNetlifyFormEmbed
      name={formName}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      subject={`Website Enquiry - ${title}`}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value={formName} />
      <p hidden>
        <label>
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      {success ? (
        <section>
          <StyledHeading>Thanks we will be in touch</StyledHeading>
        </section>
      ) : (
        <article>
          {formFields.map(({ required, inputType, label }) => {
            const fieldName = convertToKebabCase(label);
            return inputType !== 'textArea' ? (
              <div>
                <StyledInput
                  type={inputType || 'text'}
                  required={required || false}
                  placeholder={label}
                  name={fieldName}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div>
                <StyledInput
                  as="textArea"
                  required={required || false}
                  placeholder={label}
                  name={fieldName}
                  onChange={handleChange}
                />
              </div>
            );
          })}
          {formError && <span>{formError}</span>}
          <div>
            <StandardButton type="submit">Submit</StandardButton>
          </div>
        </article>
      )}
    </StyledNetlifyFormEmbed>
  );
};
