import React from 'react';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';
import styled from 'styled-components';
import { isOr, isNot } from 'styled-is';

// Hooks, Querys & State
import { useAppContext } from '../../state';
// SVGS
import Logo from '../../svg/logo-alt.svg';

// Components
import { ToolBar } from './toolBar';
import { PrimaryNav } from './primaryNav';
import { DrawNav } from './drawNav';

const StyledSiteHeader = styled((props) => <Headroom {...props} />)`
  z-index: 995 !important;
  position: relative;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem var(--pH);
    padding-top: 0;
    padding-bottom: 0;
    color: var(--textColor);
    transition: all 0.8s ease;
    background-color: transparent;
    //--
    svg {
      fill: var(--textColor);
      color: var(--textColor);
      transition: all 0.2s ease;
    }
    a svg {
      width: 100px;
    }
  }
  ${isOr('isOpen', 'reverseHeader')`
    --textColor: #ffffff;
    `}
  ${isNot('isOpen', 'reverseHeader')`
    .headroom--pinned {
      header{
        background-color: var(--docBg);
      }
    }
    `}
   @media screen and (min-width: 480px) {
    header {
      display: grid;
      grid-template-columns: 130px 1fr 130px;
      > div {
        justify-self: center;
      }
      > a svg {
        width: 200px;
      }
      > nav {
        justify-self: flex-end;
      }
    }
  }
`;

// Common Components
export const Header = () => {
  const { navOpen, toggleMenu, reverseHeader, closeNav } = useAppContext();
  return (
    <>
      <StyledSiteHeader reverseHeader={reverseHeader} isOpen={navOpen}>
        <header>
          <Link to='/' onClick={closeNav}>
            <Logo />
          </Link>
          <div>
            <PrimaryNav />
          </div>
          <ToolBar navOpen={navOpen} toggleMenu={toggleMenu} />
        </header>
      </StyledSiteHeader>
      <DrawNav />
    </>
  );
};
