import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useDate = (date) => {
  const formatedDate = dayjs(date)
    .tz('Pacific/Auckland')
    .format('D MMM YYYY h:mm a');
  return formatedDate;
};
