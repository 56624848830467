import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Hooks
import { useToggle } from '../../hooks/useToggle';
// SVG
import CloseIcon from '../../svg/close.svg';
// import CloseIcon from "../../svg/close.svg"

// Components
import { StyledHeading } from '../common';
import { PersistantModal } from '../modals/standardModal';
import { TextButton } from '../common';
import { SanityPortableText } from '../sanity';
import { NetlifyFormEmbed } from '../forms/netlifyFormEmbed';
import { GeneralDonationForm } from '../forms/generalDonationForm';
import { PlayerPartnershipForm } from '../forms/playerPartnershipForm';

const StyledModalFormInner = styled.div`
  background: #fff;
  display: grid;
  max-width: 1040px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  align-self: flex-start;
  > button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: +1;
    svg {
      width: 40px;
      stroke: var(--blue);
    }
  }
  > div {
    background: ${(props) => props.background || `#f5f5f5`};
    padding: 4rem var(--pH);
    text-align: center;
  }
  > article {
    padding: 4rem var(--pH);
    form {
      margin-top: 4rem;
    }
  }
  @media screen and (min-width: 480px) {
    grid-template-columns: 1fr 1fr;
    align-self: center;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4rem;
    }
    > article {
      align-self: center;
      padding: 4rem;
    }
  }
`;

const ModalFormComponent = ({ _type, ...props }) => {
  switch (_type) {
    case 'generalDonationForm':
      return <GeneralDonationForm {...props} />;
    case 'standardForm':
      return <NetlifyFormEmbed {...props} />;
    case 'playerPartnership':
      return <PlayerPartnershipForm {...props} />;
  }
};

const ModalFormInner = ({
  onClose,
  text,
  form,
  backgroundColor,
  formHeading,
  isOpen,
}) => {
  const { hex } = backgroundColor || {};
  return (
    <StyledModalFormInner background={hex || '#f5f5f5'}>
      <button onClick={onClose}>
        <CloseIcon />
      </button>
      <div>
        <SanityPortableText text={text} />
      </div>
      <article>
        <StyledHeading center>{formHeading}</StyledHeading>
        {form && <ModalFormComponent {...form} />}
      </article>
    </StyledModalFormInner>
  );
};

export const ModalForm = ({ linkText, ...props }) => {
  const [isOpen, setOpen] = useToggle(false);
  return (
    <>
      <TextButton onClick={() => setOpen(true)}>{linkText}</TextButton>
      <PersistantModal isOpen={isOpen}>
        <ModalFormInner
          {...props}
          onClose={() => setOpen(false)}
          isOpen={isOpen}
        />
      </PersistantModal>
    </>
  );
};
