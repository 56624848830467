import React from 'react';
import styled from 'styled-components';
import is from 'styled-is';

import { Link } from 'gatsby';

// SVG
import ArrowLeft from '../../svg/arrow-l.svg';
import ArrowRight from '../../svg/arrow-r.svg';

export const StandardButton = styled.button`
  background: var(--btnBg);
  color: var(--btnColor);
  font-family: var(--mediumFont);
  font-size: var(--h6);
  line-height: 1;
  border-radius: 80px;
  padding: 1rem 3rem;
  display: flex;
  justify-content: center;
  ${is('fullWidth')`
    width: 100%;
  `}
  ${is('minor')`
    background: transparent;
    border: 2px solid var(--btnBg);
    color: var(--btnBg);
  `}
`;

export const LinkButton = styled(Link)`
  background: var(--btnBg);
  color: var(--btnColor);
  font-family: var(--mediumFont);
  font-size: var(--h6);
  line-height: 1;
  border-radius: 80px;
  padding: 1rem 3rem 0.8rem 3rem;
  display: flex;
  justify-content: center;
  ${is('minor')`
    background: transparent;
    border: 2px solid var(--btnBg);
    color: var(--btnBg);
  `}
`;

export const TextButton = styled.button`
  color: var(--textColor);
  font-family: var(--mediumFont);
  font-size: var(--h6);
  text-decoration: underline;
`;
export const TextLink = styled(Link)`
  color: var(--textColor);
  font-family: var(--mediumFont);
  font-size: var(--h6);
  text-decoration: underline;
`;

export const ArrowButton = styled(({ direction, ...props }) => (
  <button {...props}>
    {direction === 'left' ? <ArrowLeft /> : <ArrowRight />}
  </button>
))`
  --iconSize: 20px;
  padding: 0;
  width: var(--iconSize);
  svg {
    width: var(--iconSize);
    fill: var(--textColor);
  }
  ${is('disabled')`
opacity: 0.5;
`}
  @media screen and (min-width: 1024px) {
    --iconSize: ${(props) => props.size || 20}px;
  }
`;
