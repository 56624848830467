import React from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

// Hooks
import { useAppContext } from '../../state';

// Import components
import { Header } from '../nav/header';

import { Footer } from '../footer/footer';
// Styles & Fonts
import '../../styles/fonts.css';
import { GlobalStyles } from '../../styles/globalStyles';

const StyledMainWrapper = styled.main`
  position: absolute;
  top: 0;
  width: 100%;
`;

const theme = {
  flexboxgrid: {
    // Defaults
    gutterWidth: 0,
  },
};

const Layout = ({ children }) => {
  const { activeBlockBg, pageBg, reverseText, pageTextColor } = useAppContext();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        activeBg={activeBlockBg || pageBg}
        pageTextColor={pageTextColor}
        reverseText={reverseText}
      />
      <Header />
      <StyledMainWrapper>
        {children}
        <Footer />
      </StyledMainWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
