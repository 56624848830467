import React, { createContext, useContext, useState } from 'react';

// Hooks
import { useToggle } from '../hooks';

export const AppContext = createContext({
  navOpen: false,
  reverseHeader: false,
  reverseText: false,
  pageBg: null,
  pageTextColor: null,
  activeBlockBg: null,
});

export const PageWrapper = ({ children }) => {
  // Nav
  const [navOpen, setNavOpen, toggleMenu] = useToggle(false); // eslint-disable-line

  // Pin Header
  const [headerPinned, setHeaderPinned] = useToggle(false); // eslint-disable-line

  // Header Reverse
  // eslint-disable-next-line
  const [reverseHeader, setReverseHeader] = useToggle(false);
  const [reverseText, setReverseText] = useToggle(false);

  // Background Color
  const [pageBg, setPageBg] = useState(null);

  // Page Text Color
  const [pageTextColor, setPageTextColor] = useState(null);

  const [activeBlockBg, setActiveBlockBg] = useState(null);

  const pinHeader = () => {
    setHeaderPinned(true);
  };
  const unpinHeader = () => {
    setHeaderPinned(false);
  };
  const closeNav = () => {
    setNavOpen(false);
  };

  return (
    <AppContext.Provider
      value={{
        navOpen,
        toggleMenu,
        reverseHeader,
        setReverseHeader,
        headerPinned,
        pinHeader,
        unpinHeader,
        closeNav,
        pageBg,
        setPageBg,
        activeBlockBg,
        setActiveBlockBg,
        reverseText,
        setReverseText,
        pageTextColor,
        setPageTextColor,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
