import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  /* Root Vars */
  :root {
    /* Define Colors as colors */
    --blue: #000;
    --white: #ffffff;
    --black: #000000;
    --blueGrey: #E8E7ED;

    /* Define Colors intentions */
    --primary: var(--blue);
    --reversed: var(--white);
    --lineColor: var(--blue);
    --btnBg: var(--blue);
    --btnColor: var(--white);
    --navBg: var(--blue);
    --svgFill: var(--blue);
    /* Dynamic Colors */



    /* Define Page Color */
    --pageTextColor: ${(props) =>
      props.reverseText
        ? '#fff'
        : props.pageTextColor
        ? props.pageTextColor
        : 'var(--blue)'};

    --textColor: var(--pageTextColor);
    --headingColor: var(--pageTextColor);
    --docBg: ${(props) => props.activeBg || '#fff'};
    /* Styles */
    --lineThin: solid 1px var(--lineColor);
    --line: solid 2px var(--lineColor);
    --bcLine: 10rem;

    /* Type Familys */
    --headingFont: "NeueMedium", sans-serif;
    --bodyFont: "NeueRegular", sans-serif;
    --boldFont: "NeueBold", sans-serif;
    --mediumFont: "NeueMedium", sans-serif;
    --seasonFont: "TobiasMedium", serif;

    /* Type Sizes */
    --h1: calc(45px + (90 - 45) * ((100vw - 320px) / (1800 - 320)));
    --h2: calc(32px + (67 - 32) * ((100vw - 320px) / (1800 - 320)));
    --h3: calc(24px + (50 - 24) * ((100vw - 320px) / (1800 - 320)));
    --h4: calc(22px + (37 - 22) * ((100vw - 320px) / (1800 - 320)));
    --h5: calc(20px + (29 - 20) * ((100vw - 320px) / (1800 - 320)));
    --h6: calc(19px + (21 - 19) * ((100vw - 320px) / (1800 - 320)));
    --bodyText: calc(16px + (20 - 16) * ((100vw - 320px) / (1800 - 320)));
    --smallText: calc(12px + (14 - 12) * ((100vw - 320px) / (1800 - 320)));

    /* Type Line Heights */
    --lhHeading: 1.2;
    --lhStandard: 1.9;
    /* Measures */
    --mHeading: 25em;
    --mStandard: 38em;

    /* Padding */
    --pH: 1.4rem;

    /* Container Widths */
    --cwNarrow: 1140px;
    --cwStandard: 1640px;

    /* Grid Column Gaps */
    --gridColGap: 4rem;
    --gridRowGap: 4rem;
    /* Blog Columns */
    --blogCols: 1;

  }


  /* Minimal Resets */
  html {  
      text-rendering: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-size: var(--bodyText);
    line-height: var(---lhStandard);
    background: var(--docBg);
    color: var(--textColor);
    font-family: var(--bodyFont);
    transition: background-color 0.8s ease;
  }

  * {
    font-weight: normal;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
   
  button{
    appearance: none;
    padding: 0;
  } 
  #___gatsby {
    min-height: 100vh;
  }

  img {
   width: 100%;
    height: auto;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
      margin: 0;
      padding: 0;
      font-weight: normal;
  }

  button {
      cursor: pointer;
      background: none;
      border: none;
      &:focus {
        outline:none;
      }
  }

  strong {
    font-family: var(--boldFont);
  }

  a {
    color: var(--textColor);
    text-decoration: none;
  }
  .btn {
    cursor: pointer;
  }
  ul {
    list-style-position: inside;
  }

  input, textarea {
   font-family: var(--bodyFont);
   color:  var(--textColor);
  }

  /* Medium */
  @media screen and (min-width: 480px) {
    :root {
      /* Padding */
      --pH: 6vw;
      --blogCols: 2;

    }
  }
  /* Large */
  @media screen and (min-width: 1024px) {
    :root {
      /* Styles */
      --bcLine: 15rem;
      --blogCols: 3;

    }
  }
  /* Extra Large */
 

`;
