export const useSanityPath = (props) => {
  const { _type, slug } = props || {};

  function getPath(_type, slug) {
    switch (_type) {
      case 'standardPage':
        return `/${slug.current}`;
      case 'eventCalendar':
        return `/events/${slug.current}`;
      case 'event':
        return `/events/${slug.current}`;
      case 'membershipForm':
        return `/${slug.current}`;
      case 'homePage':
        return `/`;
      default:
        return null;
    }
  }

  const path = getPath(_type, slug);

  return path;
};
