import React from 'react';

// Sanity Config
import BlockContent from '@sanity/block-content-to-react';

// Commponents
import { StyledHeading } from '../common';
import { PageLink } from './pageLink';
import { BlogQuote, BlogImage } from '../blogComponents';
import { VideoBlock } from '../pageBlocks/videoBlock';
import { ModalForm } from '../portableTextPlugs/modalForm';
import { SpotifyWidget } from '../portableTextPlugs/spotifyWidget';
import { BioReference } from '../portableTextPlugs/bioReference';
import { FileLink } from '../portableTextPlugs/fileLink';
import { SponsorLogos } from '../portableTextPlugs/sponsorLogos';
import { SubscriptionForm } from '../portableTextPlugs/subscriptionForm';

const BlockRenderer = (props) => {
  const { style = 'normal' } = props.node;

  if (/^h\d/.test(style)) {
    return (
      <StyledHeading as={style} size={style}>
        {props.children}
      </StyledHeading>
    );
  }

  if (style === 'blockquote') {
    return (
      <StyledHeading size="h4" fontReg center measure>
        {props.children}
      </StyledHeading>
    );
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const textCenter = (props) => {
  return (
    <span
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      className="test"
    >
      {props.children}
    </span>
  );
};

const pageLink = (props) => {
  return <PageLink linkText={props.children} page={props.mark.pageReference} />;
};

const artistReference = (props) => {
  return <BioReference {...props} />;
};

const link = (props) => {
  return (
    <a href={props.mark.href} target="_blank">
      {props.children}
    </a>
  );
};

export const Serializers = {
  types: {
    block: BlockRenderer,
    blogImage: ({ node }) => <BlogImage mainImage={node} />,
    blogQuote: ({ node }) => <BlogQuote {...node} />,
    blogVideo: ({ node }) => <VideoBlock {...node} />,
    modalForm: ({ node }) => <ModalForm {...node} />,
    spotifyWidget: ({ node }) => <SpotifyWidget {...node} />,
    fileLink: ({ node }) => <FileLink {...node} />,
    sponsorLogos: ({ node }) => <SponsorLogos {...node} />,
    subscriptionForm: ({ node }) => <SubscriptionForm {...node} />,
  },
  marks: { textCenter, pageLink, artistReference, link },
};
