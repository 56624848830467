import React, { useState } from 'react';
import styled from 'styled-components';
import { globalHistory } from '@reach/router';
import { loadStripe } from '@stripe/stripe-js';
import { SpringSpinner } from 'react-epic-spinners';

import NumberFormat from 'react-number-format';

import { StandardButton, StyledInput } from '../common';
import { useEffect } from 'react';

const StyledDonationForm = styled.form`
  margin-top: 0 !important;
  button {
    font-size: var(--h7);
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    > div {
      margin-left: 15px;
    }
  }
  span {
    padding: 1rem 0;
  }

  p {
    font-size: 12px;
    padding: 1rem 0;
  }

  @media (min-width: 768px) {
    p {
      margin-left: 2em;
    }
  }
`;

const StyledRadios = styled.div`
  text-align: center;
`;

const stripePromise = loadStripe('pk_live_kuAMpz7bQCN89d3bpRi4ve5v007tbJp1Kb');

async function fetchDonationCheckout(lineItems, path, subscriber) {
  const data = {
    donationType: 'subscription',
    path,
    lineItems: lineItems,
    subscriber,
  };

  const response = await fetch('/.netlify/functions/create-subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

  return response;
}

const Row = ({ label, setLineItems }) => {
  // get the value of items from the lineItems array that matches the label of the row
  const [items, setItems] = useState(0);

  useEffect(() => {
    setLineItems((prev) => {
      const index = prev.findIndex((row) => row.label === label);
      const newLineItems = [...prev];
      newLineItems[index].items = items;
      return newLineItems;
    });
  }, [items]);

  return (
    <div>
      <span style={{ fontWeight: 'bold' }}>{label}</span>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{ cursor: 'pointer', padding: '0 10px' }}
          onClick={() => items !== 0 && setItems((prev) => prev - 1)}
        >
          -
        </div>
        <span>{items}</span>
        <div
          style={{ cursor: 'pointer', padding: '0 10px' }}
          onClick={() => setItems((prev) => prev + 1)}
        >
          +
        </div>
      </div>
    </div>
  );
};

export const Subscribe = ({ fields }) => {
  const [loading, setLoading] = useState(false);

  const path = globalHistory.location.pathname;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const { sessionId } = await fetchDonationCheckout(
      lineItems,
      path,
      subscriber,
    );

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    setLoading(false);
  };

  const [lineItems, setLineItems] = useState(
    fields?.map((row) => ({ ...row, items: 0 })),
  );

  //check if every item in lineItems is 0
  const isZero = lineItems.every((row) => row.items === 0);

  //create subscriber
  // create expiry date which is 1 year from purchase date and format to YYYY-MM-DD without dayjs
  const purchaseDate = new Date().toISOString().split('T')[0];
  const expiryDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1),
  )
    .toISOString()
    .split('T')[0];

  const [subscriber, setSubscriber] = useState({
    _type: 'subscription',
    fullName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    postcode: '',
    paymentStatus: 'pending',
    items: lineItems.map((row) => {
      const { amount, label, items, _key } = row;
      return { amount, label, items, _key };
    }),
    total: lineItems.reduce((acc, row) => acc + row.amount * row.items, 0),
    purchaseDate: purchaseDate,
    expiryDate: expiryDate,
  });

  //create function to update subscriber state with the value passed in
  const updateSubscriber = (field, value) => {
    setSubscriber((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  useEffect(() => {
    setSubscriber((prev) => {
      return {
        ...prev,
        items: lineItems.map((row) => {
          const { amount, label, items, _key } = row;
          return { amount, label, items, _key };
        }),
        total: lineItems.reduce((acc, row) => acc + row.amount * row.items, 0),
      };
    });
  }, [lineItems]);

  return (
    <StyledDonationForm onSubmit={handleSubmit}>
      <StyledRadios>
        <StyledInput
          style={{ width: '100%' }}
          onChange={(e) => updateSubscriber('fullName', e.target.value)}
          type="text"
          placeholder="Full Name"
          required
        />
        <StyledInput
          style={{ width: '100%' }}
          onChange={(e) => updateSubscriber('email', e.target.value)}
          type="email"
          placeholder="Email"
          required
        />
        <StyledInput
          style={{ width: '100%' }}
          onChange={(e) => updateSubscriber('phone', e.target.value)}
          type="text"
          placeholder="Phone"
        />
        <StyledInput
          style={{ width: '100%' }}
          onChange={(e) => updateSubscriber('address', e.target.value)}
          type="text"
          placeholder="Address"
        />
        <StyledInput
          style={{ width: '100%' }}
          onChange={(e) => updateSubscriber('city', e.target.value)}
          type="text"
          placeholder="City"
        />
        <StyledInput
          style={{ width: '100%' }}
          onChange={(e) => updateSubscriber('postcode', e.target.value)}
          type="text"
          placeholder="Postcode"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          {fields?.map((row, i) => (
            <Row
              key={i}
              {...row}
              lineItems={lineItems}
              setLineItems={setLineItems}
            />
          ))}
        </div>
        <p style={{ textAlign: 'center', marginLeft: '0', padding: 0 }}>
          *
          <a
            style={{ textDecoration: 'underline' }}
            href="https://stripe.com/en-nz/pricing/local-payment-methods"
            target="_blank"
            rel="noreferrer"
          >
            Stripe Checkout fee
          </a>{' '}
          applied automatically
        </p>
      </StyledRadios>
      <StandardButton type="submit" disabled={isZero}>
        {loading ? 'Loading' : 'Submit'}
        {loading && <SpringSpinner color="white" size={25} />}
      </StandardButton>
    </StyledDonationForm>
  );
};
