import { useStaticQuery, graphql } from 'gatsby';

export const useFooterSettings = () => {
  const { sanityFooterSettings } = useStaticQuery(graphql`
    query {
      sanityFooterSettings {
        footerText {
          _rawTextBlock(resolveReferences: { maxDepth: 10 })
        }
        mcSubList
        funders {
          groupName
          funders {
            website
            ...ImageWithPreview
          }
        }
      }
    }
  `);
  return sanityFooterSettings || {};
};
