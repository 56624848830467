import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import AddIcon from '../../svg/add.svg';
import MinusIcon from '../../svg/dec.svg';

import { Row } from 'react-styled-flexboxgrid';

const StyledOptionSelector = styled.button`
  font-family: var(--mediumFont);
  font-size: var(--h7);
  color: var(--textColor);
  position: relative;
  text-align: center;
  padding: 0;
  > div {
    border-top: 1px solid #dfdfdf;
    position: relative;
    z-index: 1;
    padding: 1.3rem 0;
  }
  &:last-child div {
    border-bottom: 1px solid #dfdfdf;
  }
  > span {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #f5f5f5;
    top: 0;
    left: 0;
    z-index: 0;
  }
`;

export const AnimatedOptionSelector = ({ children, isActive, ...props }) => {
  return (
    <StyledOptionSelector {...props}>
      <motion.div>{children}</motion.div>
      {isActive && (
        <motion.span
          layoutId='circle'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
      )}
    </StyledOptionSelector>
  );
};

const StyledQuantitySelector = styled(Row)`
  > div {
    background: #faf3ee;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 15px;
      height: 15px;
    }
  }
  > span {
    margin: 0 0.5rem;
    font-size: var(--h6);
    font-family: var(--mediumFont);
    margin-top: 0.2rem;
    width: 2rem;
    text-align: center;
  }
`;

export const QuantitySelector = ({ inc, dec, qty }) => {
  return (
    <StyledQuantitySelector between='xs' middle='xs' className='qtySelector'>
      <div onClick={dec}>
        <MinusIcon />
      </div>
      <span>{qty}</span>
      <div onClick={inc}>
        <AddIcon />
      </div>
    </StyledQuantitySelector>
  );
};
