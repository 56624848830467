import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-styled-flexboxgrid';

// Components
import { MenuToggle } from './menuToggle';

// Styles
const StyledSearchButton = styled.button`
  svg {
    width: 25px;
    margin-right: 2rem;
  }
`;

export const ToolBar = ({ navOpen, toggleMenu }) => {
  return (
    <nav>
      <MenuToggle isOpen={navOpen} onClick={toggleMenu} />
    </nav>
  );
};
