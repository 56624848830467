import React from 'react';
import styled from 'styled-components';
import is from 'styled-is';

import clientConfig from '../../client-config';

// Import Components
import BlockContent from '@sanity/block-content-to-react';
import { Serializers } from './serializers';

// Styles
const StyledPortableText = styled.div`
  font-size: var(--bodyText);
  line-height: ${(props) =>
    props.tightLineHeight ? '1.25' : 'var(--lhStandard)'};
  p {
    margin-bottom: 0.3em;
  }
  figure {
    margin-left: 0;
    margin-right: 0;
  }
  li {
    margin-left: 1.2rem;
    list-style-position: outside;
  }
  ${is('measureText')`
    p{
      max-width: var(--mStandard);
    }
    h1,h2,h3,h4,h5,h6 {
      max-width: var(--mHeading);
    }
  `}
  ${is('centerText', 'measureText')`
    p,h1,h2,h3,h4,h5,h6{
      margin-left: auto;
      margin-right: auto;
    }
  `}
  ${is('centerText')`
    text-align: center;
  `}
  ${is('color')`
    color: ${(props) => props.color};
  `}
`;

export const SanityPortableText = ({
  text,
  measureText,
  centerText,
  color,
  tightLineHeight,
}) => {
  return (
    <StyledPortableText
      className="sanity-portble-text"
      measureText={measureText}
      centerText={centerText}
      color={color}
      tightLineHeight={tightLineHeight}
    >
      <BlockContent
        blocks={text}
        serializers={Serializers}
        {...clientConfig.sanity}
      />
    </StyledPortableText>
  );
};
