import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import styled from 'styled-components';

// Hooks & Querys
import { useFooterSettings } from '../../GraphQl/useFooterSettings';

// Svg
import LogoIcon from '../../svg/logo-alt.svg';
import BraveLogo from '../../svg/brave.svg';

// Components
import { SanityPortableText } from '../sanity';
import { StandardContainer, StyledHeading } from '../common';
import { SubscribeForm } from './subscribeForm';

// Styles
const StyledFooter = styled.footer`
  --textColor: #fff;
  --headingColor: #fff;
  background: var(--blue);
  color: var(--textColor);
  padding: 4rem 0;

  .home-link {
    svg {
      width: 100px;
      fill: var(--textColor);
    }
  }

  > ${StandardContainer} {
    display: grid;
    grid-row-gap: 2rem;

    // svg
    svg {
      width: 200px;
    }
  }

  /* Large */
  @media screen and (min-width: 1024px) {
    > ${StandardContainer} {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
    }
  }
`;
const StyledFooterFunders = styled.section`
  display: grid;
  grid-row-gap: 1rem;
`;
const StyledFooterFunder = styled.div`
  > article {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.5rem;
  }
  @media screen and (min-width: 1024px) {
    > article {
      grid-template-columns: repeat(auto-fit, minmax(90px, 100px));
    }
  }
`;

const StyledFooterText = styled.section`
  --lhStandard: 1.4;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
`;

const StyledBottomFooter = styled.div`
  --textColor: rgba(255, 255, 255, 0.8);

  border-top: 1px solid rgba(255, 255, 255, 0.4);
  margin-top: 2rem;
  padding: 2rem 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    > p {
      color: var(--textColor);
      margin-bottom: 0.5rem;
    }
    > a {
      svg {
        fill: var(--textColor);
        width: 120px;
      }
    }
  }
  /* Medium */
  @media screen and (min-width: 480px) {
    > div {
      flex-direction: row;
      > p {
        margin-bottom: 0;
      }
    }
  }
`;

const FooterFunder = ({ funders, groupName }) => {
  return (
    <StyledFooterFunder>
      <StyledHeading size="h7" as="h6">
        {groupName}
      </StyledHeading>
      <article>
        {funders.map(({ website, ...props }) => {
          return (
            <a href={website} target="_blank" rel="noreferrer">
              <Image {...props} />
            </a>
          );
        })}
      </article>
    </StyledFooterFunder>
  );
};

export const Footer = () => {
  const { mcSubList, footerText, funders } = useFooterSettings();
  return (
    <StyledFooter>
      <StandardContainer padH wrappedStandard>
        <section>
          <Link to="/" className="home-link">
            <LogoIcon />
          </Link>
        </section>
        <SubscribeForm mcList={mcSubList} />
        <StyledFooterText>
          {footerText.map(({ _rawTextBlock }) => (
            <SanityPortableText text={_rawTextBlock} />
          ))}
        </StyledFooterText>
        <StyledFooterFunders>
          {funders.map((item) => (
            <FooterFunder {...item} />
          ))}
        </StyledFooterFunders>
      </StandardContainer>
      <StyledBottomFooter>
        <StandardContainer padH>
          <p>&copy; 2020 Christchurch Symphony Orchestra</p>
          <a href="https://bravedigital.nz/" target="_blank">
            <BraveLogo />
          </a>
        </StandardContainer>
      </StyledBottomFooter>
    </StyledFooter>
  );
};
