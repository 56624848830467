import React from 'react';
import styled from 'styled-components';
import Mailchimp from 'react-mailchimp-form';

// Components
import { StyledHeading } from '../common';

// Styles
const StyledMCform = styled(Mailchimp)`
  display: grid;
  grid-template-columns: 1fr auto;
  * {
    color: var(--textColor);
    font-size: var(--bodyText);
  }
  > input {
    background: transparent;
    grid-row: 1;
    grid-column: 1 / span 2;
    border: none;
    border-bottom: 1px solid var(--textColor);
    padding: 0.5rem 0;
    &:focus {
      outline: none;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--textColor);
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--textColor);
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      color: var(--textColor);
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: var(--textColor);
    }
  }
  > button {
    grid-row: 1;
    grid-column: 2;
    align-self: center;
    color: var(--textColor);
    font-family: var(--mediumFont);
  }
`;

export const SubscribeForm = ({ mcList }) => {
  return (
    <section>
      <StyledHeading>Subscribe to our newsletter</StyledHeading>
      <StyledMCform
        action={mcList}
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Your email address',
            type: 'email',
            required: true,
          },
        ]}
        messages={{
          sending: 'Sending...',
          success: 'Thank you for subscribing!',
          error: 'An unexpected internal error has occurred.',
          empty: 'You must write an e-mail.',
          button: 'Subscribe',
        }}
      />
    </section>
  );
};
