import React from 'react';

import { PageWrapper } from './src/state';
import { ToastProvider } from 'react-toast-notifications';

import { CustomToast } from './src/components/common';

export const wrapRootElement = ({ element }) => {
  return (
    <ToastProvider components={{ Toast: CustomToast }}>
      <PageWrapper>{element}</PageWrapper>
    </ToastProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {/* <CustomerChat /> */}
      {element}
    </>
  );
};
