import React from 'react';
import styled from 'styled-components';
import { DefaultToast } from 'react-toast-notifications';

const StyledToastNotification = styled(DefaultToast)`
  --textColor: #003b36;
  --bgColor: #deefdf;
  background-color: var(--white) !important;
  color: var(--textColor);
  .react-toast-notifications__toast__icon-wrapper {
    background: var(--bgColor);
    color: var(--textColor);
  }
`;

export const CustomToast = ({ children, ...props }) => {
  return (
    <StyledToastNotification {...props}>{children}</StyledToastNotification>
  );
};
