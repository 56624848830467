import React, { useState } from 'react';
import styled from 'styled-components';
import { globalHistory } from '@reach/router';
import { loadStripe } from '@stripe/stripe-js';
import { SpringSpinner } from 'react-epic-spinners';

import NumberFormat from 'react-number-format';

import { StandardButton, StyledCurrencyInput } from '../common';

const StyledDonationForm = styled.form`
  button {
    font-size: var(--h7);
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    > div {
      margin-left: 15px;
    }
  }
  span {
    padding: 1rem 0;
  }

  p {
    font-size: 12px;
    padding: 1rem 0;
  }

  @media (min-width: 768px) {
    p {
      margin-left: 2em;
    }
  }
`;

const stripePromise = loadStripe('pk_live_kuAMpz7bQCN89d3bpRi4ve5v007tbJp1Kb');

async function fetchDonationCheckout(amount, path, category) {
  const data = {
    donationType: 'generalDonation',
    lineTitle: category || 'General Donation',
    amount,
    path,
  };
  const response = await fetch('/.netlify/functions/create-donation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

  return response;
}

export const GeneralDonationForm = ({ category }) => {
  const [donationAmount, setDonationAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const path = globalHistory.location.pathname;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!donationAmount || donationAmount < 500) {
      setError('Minimum Donation $5.00');
      return;
    }

    const { sessionId } = await fetchDonationCheckout(
      donationAmount,
      path,
      category,
    );

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    setLoading(false);
  };

  return (
    <StyledDonationForm onSubmit={handleSubmit}>
      <StyledCurrencyInput>
        <NumberFormat
          thousandSeparator={true}
          prefix={'$'}
          onValueChange={({ value }) =>
            setDonationAmount((parseFloat(value) * 100 * 1.029 + 30).toFixed(0))
          }
          placeholder="$ Enter Amount"
        />
        {error && <span>{error}</span>}
      </StyledCurrencyInput>
      <p>
        *
        <a
          style={{ textDecoration: 'underline' }}
          href="https://stripe.com/en-nz/pricing/local-payment-methods"
          target="_blank"
          rel="noreferrer"
        >
          Stripe Checkout fee
        </a>{' '}
        applied automatically
      </p>
      <StandardButton type="submit" disabled={!donationAmount}>
        {loading ? 'Loading' : 'Submit'}
        {loading && <SpringSpinner color="white" size={25} />}
      </StandardButton>
    </StyledDonationForm>
  );
};
