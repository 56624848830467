import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimateSharedLayout } from 'framer-motion';

import { globalHistory } from '@reach/router';
import { loadStripe } from '@stripe/stripe-js';

import { StandardButton, AnimatedOptionSelector } from '../common';

const StyledPartnershipForm = styled.div`
  > article {
    display: grid;
    margin-top: 2rem;
  }
  > button {
    font-size: var(--h7);
    display: block;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const stripePromise = loadStripe('pk_live_kuAMpz7bQCN89d3bpRi4ve5v007tbJp1Kb');

async function fetchChairDonationCheckout(
  lineTitle,
  amount,
  path,
  selectedChair,
  title,
) {
  const data = {
    lineTitle,
    amount,
    path,
    chairSponsored: selectedChair,
    chairType: title,
  };
  const response = await fetch('/.netlify/functions/create-partner-donation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

  return response;
}

export const PlayerPartnershipForm = ({
  chairsAvailable,
  sponsorshipPrice,
  title,
}) => {
  const [selectedChair, setSelectedChair] = useState(null);
  const [loading, setLoading] = useState(false);
  const path = globalHistory.location.pathname;

  const handleSubmit = async () => {
    setLoading(true);

    const formatedTotal = parseFloat(
      (sponsorshipPrice * 100 * 1.029 + 30).toFixed(0),
    );

    const { sessionId } = await fetchChairDonationCheckout(
      `${title} - ${selectedChair}`,
      formatedTotal,
      path,
      selectedChair,
      title,
    );

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    setLoading(false);
  };

  return (
    <StyledPartnershipForm>
      <AnimateSharedLayout>
        <article>
          {chairsAvailable && (
            <>
              {chairsAvailable.map((item) => (
                <AnimatedOptionSelector
                  onClick={() => setSelectedChair(item)}
                  isActive={selectedChair === item}
                >
                  {item}
                </AnimatedOptionSelector>
              ))}
            </>
          )}
        </article>
        <StandardButton
          disabled={!selectedChair}
          onClick={() => handleSubmit()}
        >
          {loading ? 'Loading' : 'Checkout'}
        </StandardButton>
        <p style={{ marginTop: '1rem', fontSize: '14px', textAlign: 'center' }}>
          *
          <a
            style={{ textDecoration: 'underline' }}
            href="https://stripe.com/en-nz/pricing/local-payment-methods"
            target="_blank"
            rel="noreferrer"
          >
            Stripe Checkout fee
          </a>{' '}
          applied automatically
        </p>
      </AnimateSharedLayout>
    </StyledPartnershipForm>
  );
};
