import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Hooks
import { useSanityPath } from '../../hooks';

import { LinkButton } from '../common';

// Styles
const StyledPageLink = styled(Link)`
  text-decoration: none;
`;

export const PageLink = ({ page, linkText, ...props }) => {
  const path = useSanityPath(page);
  return (
    <StyledPageLink {...props} to={path}>
      {linkText}
    </StyledPageLink>
  );
};
export const PageLinkButton = ({ page, linkText, ...props }) => {
  const path = useSanityPath(page);
  return (
    <LinkButton {...props} to={path}>
      {linkText}
    </LinkButton>
  );
};
